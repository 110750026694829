<template>
  <v-card-text>
    <v-container>
      <v-row>
        <v-col v-if="passenger.itemImage" cols="12" lg="12" md="12" sm="12" class="my-2">
          <img :src="passenger.itemImage" alt="Profile Image" class="profile__image" />
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="4" class="info__wrapper my-2">
          <span class="bold">
            {{ $t('formsAndTables.firstName') }}
          </span>
          <span>
            {{ passenger.first_name || '/' }}
          </span>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="4" class="info__wrapper my-2">
          <span class="bold">
            {{ $t('formsAndTables.lastName') }}
          </span>
          <span>
            {{ passenger.last_name || '/' }}
          </span>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="4" class="info__wrapper my-2">
          <span class="bold">
            {{ $t('formsAndTables.phoneNumber') }}
          </span>
          <span>
            {{ passenger.phone || '/' }}
          </span>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="4" class="info__wrapper my-2">
          <span class="bold">
            {{ $t('formsAndTables.email') }}
          </span>
          <span>
            {{ passenger.email || '/' }}
          </span>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="4" class="info__wrapper my-2">
          <span class="bold">
            {{ $t('formsAndTables.passport') }}
          </span>
          <span>
            {{ passenger.passport || '/' }}
          </span>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="4" class="info__wrapper my-2">
          <span class="bold">
            {{ $t('formsAndTables.country') }}
          </span>
          <span>
            {{ passenger.country || '/' }}
          </span>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="4" class="info__wrapper my-2">
          <span class="bold">
            {{ $t('formsAndTables.nationality') }}
          </span>
          <span>
            {{ passenger.nationality || '/' }}
          </span>
        </v-col>
      </v-row>
    </v-container>
  </v-card-text>
</template>

<script>
export default {
  name: 'ClientGeneralInfo',
  props: ['passenger'],
  data: () => ({}),
  methods: {},
};
</script>

<style scoped lang="scss">
.profile__image {
  height: 200px;
  width: auto;
}

.info__wrapper {
  span {
    display: block;

    color: #1b1d20;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 100% */

    &.bold {
      color: #444;

      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px; /* 88.889% */
      margin-bottom: 1rem;
    }
  }
}
</style>
