<template>
  <v-container id="inspire" fluid>
    <v-row>
      <v-col class="mb-5" cols="auto" md="12" sm="12">
        <v-card>
          <v-data-table
            :footer-props="{
              pageText: `{0}-{1} ${$t('tables.of')} {2}`,
              itemsPerPageText: $t('tables.drivingsByPage'),
              itemsPerPageOptions: [5, 10, 20, 50, 100],
            }"
            :headers="drivingsHeaders"
            :no-data-text="$t('tables.noDataText')"
            :no-results-text="$t('tables.noDataText')"
            :items="drivings"
            :search="searchDrivingString"
            class="elevation-1 cursor-pointer"
            :items-per-page="20"
            multi-sort>
            <template v-slot:item.number="{ item }" class="d-flex align-center">
              <div class="d-flex align-center">
                {{ item.number }}
                <v-img
                  v-if="item.gnetData"
                  alt="GNET"
                  class="shrink ml-1"
                  contain
                  src="@/assets/gnet-small.png"
                  transition="scale-transition"
                  height="26px"
                  width="28px" />
              </div>
            </template>

            <template v-slot:top>
              <v-toolbar flat>
                <v-text-field
                  v-model="searchDrivingString"
                  append-icon="mdi-magnify"
                  hide-details
                  :label="$t('tables.search')"
                  single-line></v-text-field>

                <v-spacer />
              </v-toolbar>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-tooltip left>
                    <template v-slot:activator="{ on: tooltipOn }">
                      <v-btn text v-on="{ ...on, ...tooltipOn }">
                        <v-icon> mdi-dots-vertical </v-icon>
                      </v-btn>
                    </template>
                    <span>
                      {{ $t('buttons.actions') }}
                    </span>
                  </v-tooltip>
                </template>

                <v-list>
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item @click="openTransfer(item)">
                        <v-icon class="mx-1" small v-bind="attrs">mdi-eye</v-icon>
                        <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                          {{ $t('buttons.open') }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <span>{{ $t('tooltips.open') }}</span>
                  </v-tooltip>

                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item @click="printInvoice(item)">
                        <v-icon class="mx-1" small v-bind="attrs">mdi-file</v-icon>
                        <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                          {{ $t('buttons.printInvoice') }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <span>{{ $t('tooltips.printInvoice') }}</span>
                  </v-tooltip>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import i18n from '@/i18n/i18n';
import { customersDrivingsHeaders } from '@/mixins/data-table-headers';
import router from '@/router';

export default {
  name: 'DrivingsTable',
  props: ['drivings'],
  components: {},
  data: () => ({
    searchDrivingString: '',
  }),
  created() {},
  computed: {
    drivingsHeaders() {
      return customersDrivingsHeaders(i18n);
    },
  },
  methods: {
    openTransfer(item) {
      // Resolve the URL from the router
      const routeData = router.resolve({
        name: this.$store.getters['auth/role'] == 1 ? 'TabularView' : 'CalendarView',
        query: { driving_id: item?.id },
      });

      // Open a new tab with the resolved URL
      window.open(routeData.href, '_blank');
    },

    printInvoice(driving) {
      this.$store
        .dispatch('drivings/printInvoice', driving?.id)
        .then((res) => {

          let file = '';
          if (!res.path.includes("https://") && !res.path.includes("http://")) {
            file = process.env.VUE_APP_BACKEND_URL.concat(res.path.replace('public', 'storage'));
          }else{
            file = res.path;
          }

          window.open(file, '_blank');
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        });
    },
  },
  watch: {},
};
</script>
